import {Box, Card, CardHeader, Container, IconButton,Link} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import api from '../api';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate,Link as RouterLink } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';

export default function LeaderBoard() {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  let navigate = useNavigate();

  useEffect(() => {
    loadData();

  },[]);

  useEffect(() => {
    loadData();
  },[page]);

  const loadData = () => {
    api.get('/leaderboard?page='+page).then(function(response) { return response; })
        .then(function(resultData) {
          if (resultData.success){
            setData(resultData.data);
          }
        });
  }
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
      <Page title="Leaderboard view | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <Card sx={{mt:3}}>
            <CardHeader title={ "Leaderboard for "+data.period } />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
              <Table sx={{ width: '100%' }} aria-label="table bordered">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">№</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Points</TableCell>
                    <TableCell align="right">Active days</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {data?.data?.map((row) => (
                      <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          selected={ api.getUserId() == row.user_id?true:false }
                      >
                        <TableCell align="center">{row.rn}</TableCell>
                        <TableCell component="th" scope="row">
                          <Link component={RouterLink} to={`/dashboard/home/${row.user_id}`}>
                            {row.user_full_name}
                          </Link>
                        </TableCell>
                        <TableCell align="right">{row.total_sum}</TableCell>
                        <TableCell align="right">{row.user_active_days}</TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
            </Box>
          </Card>


        </Container>
      </Page>
  );
}