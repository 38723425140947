import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import Logout from './pages/Logout';
import api from './api';
import Wordlist from './pages/Wordlist';
import Folder from './pages/Folder';
import WordlistView from './pages/WordlistView';
import Write from './pages/Write';
import WriteWithAudio from './pages/WriteWithAudio';
import Test from './pages/Test';
import Match from './pages/Match';
import ReadText from './pages/ReadText';

import Speech from "./pages/Speech";
import TrueOrFalse from "./pages/TrueOrFalse";
import MyFolders from "./pages/MyFolders";
import SentenceList from "./pages/SentenceList";
import LeaderBoard from "./pages/LeaderBoard";
import SettingsLongRepeat from "./pages/SettingsLongRepeat";
import Settings from "./pages/Settings";
import Books from "./pages/Books";
import BookRead from "./pages/BookRead";
import Leagues from "./pages/Leagues";
import LeagueUsers from "./pages/LeagueUsers";
import NeedRepeat from "./pages/NeedRepeat";

// ----------------------------------------------------------------------

export default function Router() {
  const isLogged = api.chekAuth();
  return useRoutes([
    {
      path: '/dashboard',
      element: isLogged == false ? <Navigate to="/login" /> : <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: isLogged == false ? <Navigate to="/login" /> :  <DashboardApp /> },
        { path: 'home/:userID', element: isLogged == false ? <Navigate to="/login" /> :  <DashboardApp /> },
        { path: 'folders', element: isLogged == false ? <Navigate to="/login" /> :  <Folder /> },
        { path: 'my-folders', element: isLogged == false ? <Navigate to="/login" /> :  <MyFolders /> },
        { path: 'folder/:folderId', element: isLogged == false ? <Navigate to="/login" /> :  <Wordlist /> },
        { path: 'wordlist/:wordlist_id', element: isLogged == false ? <Navigate to="/login" /> :  <WordlistView /> },
        { path: 'wordlist/:wordlist_id/example-sentences', element: isLogged == false ? <Navigate to="/login" /> :  <SentenceList /> },
        { path: 'wordlist/:wordlist_id/write/unstudied', element: isLogged == false ? <Navigate to="/login" /> :  <Write type={'unstudied'} /> },
        { path: 'wordlist/:wordlist_id/test/unstudied', element: isLogged == false ? <Navigate to="/login" /> :  <Test type={'unstudied'} /> },
        { path: 'wordlist/:wordlist_id/write', element: isLogged == false ? <Navigate to="/login" /> :  <Write /> },
        { path: 'wordlist/:wordlist_id/speech', element: isLogged == false ? <Navigate to="/login" /> :  <Speech /> },
        { path: 'wordlist/:wordlist_id/write-with-audio', element: isLogged == false ? <Navigate to="/login" /> :  <WriteWithAudio /> },
        { path: 'wordlist/:wordlist_id/test', element: isLogged == false ? <Navigate to="/login" /> :  <Test /> },
        { path: 'wordlist/:wordlist_id/true-false', element: isLogged == false ? <Navigate to="/login" /> :  <TrueOrFalse /> },
        { path: 'wordlist/:wordlist_id/match', element: isLogged === false ? <Navigate to="/login" /> :  <Match /> },
        { path: 'wordlist/:wordlist_id/read-text', element: isLogged === false ? <Navigate to="/login" /> :  <ReadText /> },
        { path: 'random', element: isLogged == false ? <Navigate to="/login" /> :  <Wordlist /> },
        { path: 'settings', element: isLogged == false ? <Navigate to="/login" /> :  <Settings /> },
        { path: 'leaderboard', element: isLogged == false ? <Navigate to="/login" /> :  <LeaderBoard /> },
        { path: 'leagues', element: isLogged == false ? <Navigate to="/login" /> :  <Leagues /> },
        { path: 'league/:league_id/users', element: isLogged == false ? <Navigate to="/login" /> :  <LeagueUsers /> },
        { path: 'settings/long-repeat', element: isLogged == false ? <Navigate to="/login" /> :  <SettingsLongRepeat /> },
        { path: 'books', element: isLogged == false ? <Navigate to="/login" /> :  <Books /> },
        { path: 'book/:book_id', element: isLogged == false ? <Navigate to="/login" /> :  <BookRead /> },
        { path: 'need-repeat', element: isLogged == false ? <Navigate to="/login" /> :  <NeedRepeat /> },
        { path: 'need-repeat/:period_repeat', element: isLogged == false ? <Navigate to="/login" /> :  <NeedRepeat /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
