// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppTotalWords,
  AppUserErrorWords,
  AppFolders,
  AppUserSuccessWords,
} from '../components/_dashboard/app';
import { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import config from '../config.json';
import api from '../api';
import AppActiveDays from '../components/_dashboard/app/AppActiveDays';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [userdata, setUserData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [user, setUser] = useState([]);
  let { userID } = useParams();

  useEffect(() => {
    if (!userID) userID = api.getUserId();
    axios.get(config.apiUrl+'/user/dashboard/'+userID, {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response)=>{
      if (response.data.success){
        setUserData(response.data.data.totals);
        setChartData(response.data.data.chart);
        setUser(response.data.data.user);
        api.getUser();
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
    api.updateUserData();
  },[]);


  return (
    <Page title="Dashboard | Eagles.uz">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Dashboard for <strong  style={{color: '#00AB55'}}>{user.user_full_name}</strong></Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppFolders total={userdata.folders_count}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalWords total={userdata.total_words} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppUserSuccessWords total={userdata.total_success}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppUserErrorWords total={userdata.total_error}/>
          </Grid>


        </Grid>
        {chartData.length == 0?'':<AppActiveDays chart_data={chartData}/>}
      </Container>
    </Page>
  );
}
